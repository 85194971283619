.whyUs {
  padding: 3rem 1.5rem;
  text-align: center;
  width: 90%;
  margin: auto;
}
@media (max-width: 768px) {
  .whyUs {
    padding: 10px 0;
    width: 100%;
  }
}
.whyUs h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}
@media (max-width: 768px) {
  .whyUs h2 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
}
.whyUs .whyUsContent {
  display: flex;
  width: 100%;
  gap:1rem;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .whyUs .whyUsContent {
    width: 100%;
    flex-direction: column;
  }
}
.whyUs .whyUsContent .whyUsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  text-align: left;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.whyUs .whyUsContent .whyUsRow:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .whyUs .whyUsContent .whyUsRow {
    flex-direction: column;
    text-align: center;
    width: auto;
    padding: 15px;
  }
}
.whyUs .whyUsContent .whyUsRow img {
  width: 80px;
}
.whyUs .whyUsContent .whyUsRow h3 {
  font-size: 20px;
  margin-bottom: 0.5rem;
  color: rgb(246, 116, 29);
  text-align: center;
}
@media (max-width: 768px) {
  .whyUs .whyUsContent .whyUsRow h3 {
    font-size: 16px;
  }
}
.whyUs .whyUsContent .whyUsRow p {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}