.banner {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .banner {
    display: none;
  }
}
.banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
}
.banner .text h2{
  text-align: center;
  margin-top: 150px;
  position: absolute;
  color: white;
  font-size: 42px;
  font-weight: 700;
  width: 100%;
}

.contactSection {
  width: 80%;
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: baseline;
  justify-content: space-between;
  margin: auto;
  gap:3rem;
}
@media (max-width: 768px) {
  .contactSection {
    background-color: rgba(205, 200, 200, 0.3960784314);
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 150px 0 0 0;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .contactSection .infoRow {
    width: 100%;
    margin: auto;
  }
}
.contactSection .infoRow ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.contactSection .infoRow ul li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.contactSection .infoRow ul li svg {
  margin-right: 10px;
  font-size: 1.5rem;
}
.contactSection .infoRow ul li span {
  font-size: 1.2rem;
}
.contactSection .formRow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.contactSection .formRow .inputCol {
  flex: 1;
  padding: 0 10px;
  margin-bottom: 1rem;
}
.contactSection .formRow .inputCol input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}
.contactSection .messageRow {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.contactSection .messageRow textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
  height: 180px;
}
.contactSection .button {
  display: flex;
  justify-content: flex-end;
}
.contactSection .button button {
  align-self: flex-end;
  padding: 0.75rem 1.5rem;
  background-color: #0070f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .contactSection .button button {
    width: 100%;
    margin: auto;
  }
}
.contactSection .button button:hover {
  background-color: #005bb5;
}

@media (max-width: 768px) {
  .formRow {
    flex-direction: column;
  }
  .formRow .inputCol {
    padding: 0;
  }
  .messageRow textarea {
    height: 100px;
  }
}