.terms {
  padding: 150px 30px;
  background-color: rgba(190, 187, 187, 0.448);
}
.terms h1 {
  font-size: 22px;
  font-weight: 500;
  margin: 10px 0;
}
.terms h2 {
  font-size: 22px;
  margin: 10px 0;
}
.terms p,
.terms li {
  font-size: 16px;
  margin: 5px 0;
}
.terms li {
  list-style: disc;
}