
.aboutUs {
  text-align: center;
  width: 95%;
  margin: auto;
  padding: 20px;
  transition: background-color 0.5s ease;
}
@media (max-width: 768px) {
  .aboutUs {
   padding: 10px 0;
    width: 100%;
  }
}

.aboutUs:hover {
  background-color: #f0f0f0;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  animation: fadeInDown 1s ease-in-out;
}

h3 {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #444;
  position: relative;
}

h3::after {
  content: '';
  width: 200px;
  height: 3px;
  background-color: #da6e11;
  position: absolute;
  bottom: -10px;
  left: 0;
  transition: width 0.3s ease;
}

h3:hover::after {
  width: 100px;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  transition: color 0.4s ease;
}

p:hover {
  color: #333;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
