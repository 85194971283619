.banner {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .banner {
    height: 30vh;
  }
}
.text h2{
  color: white;
}
  
  .bannerImage {
    width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  }
  .text {
    text-align: center;
    margin-top: 150px;
    position: absolute;
    color: white;
    font-size: 42px;
    font-weight: 700;
    width: 100%;
  }



@media (max-width: 768px) {
  .banner {
    height: 30vh;
  }
}
