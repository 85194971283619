.footer {
  padding: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: 250px;
  height: 120px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0;
  }
}
.social{
  width: 40px;
  height: 40px;
}
.link,
li > a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
}
.footerSocial {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerCard {
  width: 70%;
  margin: 20px 0;
}
.footerCard ul {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footerCard ul {
    width: 100%;
    flex-direction: column;
  }
}
.footerCard > h2 {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.634);
  margin-block: 1rem;
  padding-block: 1rem;
}
.footerCard > ul > li {
  margin: 0.5rem 0;
}
.footerCard > p {
  font-size: small;
} 