.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}



.bookForm {
  display: flex;
  gap: 12px;
  align-items: center;
}

.destination {
  flex: 1;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-size: 16px;
}

.date {
  width: 160px;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  height: 48px;
  padding: 0 32px;
  background-color: #006ce4;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}

.button:hover {
  background-color: #0055b3;
}

/* Focus states */
.destination:focus,
.date:focus {
  outline: none;
  border-color: #006ce4;
  box-shadow: 0 0 0 2px rgba(0, 108, 228, 0.2);
}

/* Placeholder styling */
.destination::placeholder {
  color: #6b6b6b;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
  
  .date {
    width: 140px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .bookForm {
    flex-wrap: wrap;
    gap: 10px;
  }

  .destination {
    flex: 100%;
    width: 100%;
  }

  .date {
    flex: 1;
    min-width: 140px;
  }

  .button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 12px;
  }

  .header h1 {
    font-size: 20px;
  }

  .header p {
    font-size: 13px;
  }

  .bookForm {
    flex-direction: column;
    gap: 8px;
  }

  .destination,
  .date,
  .button {
    width: 90%;
  }

  .date {
    width: 90%;
  }
}

/* Date input icon styling */
.date::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/* Input active states */
.destination:active,
.date:active {
  border-color: #006ce4;
}

/* Fix spacing issues on smaller screens */
@media (max-width: 360px) {
  .container {
    padding: 10px;
  }
  
  .bookForm {
    gap: 6px;
  }
}