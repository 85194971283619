.title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
  text-align: center;
  margin: 50px 0;
}
@media (max-width: 768px) {
  .title {
    margin: 15px;
    font-size: 20px;
    color: #464646;
    font-weight: 600;
    width: 100%;
  }
}
.images{
  width: 100%;
}
.panels {
  margin: 70px 0;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
}
@media (max-width: 768px) {
  .panels {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 10px 0;
  }
}

.panel {
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.1);
  color: white;
  text-align: center;
  align-items: center;
  transition: font-size 0.7s cubic-bezier(0.61, -0.19, 0.7, -0.11), flex 0.7s cubic-bezier(0.61, -0.19, 0.7, -0.11), background 0.2s;
  font-size: 20px;
  background-size: cover;
  background-position: center;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.panel1 {
  background-image: url("../../images/country/spain.webp");
}

.panel2 {
  background-image: url("../../images/country/indonesia.webp");
}

.panel3 {
  background-image: url("../..//images/country/canada.webp");
}

.panel4 {
  background-image: url("../../images/country/ireland.webp");
}

.panel5 {
  background-image: url("../../images/country/greece.webp");
}

.panel > * {
  margin: 0;
  width: 100%;
  transition: transform 0.5s;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel > *:first-child {
  transform: translateY(-100%);
}

.open-active > *:first-child {
  transform: translateY(0);
}

.panel > *:last-child {
  transform: translateY(100%);
}
@media (max-width: 768px) {
  .panel > *:last-child {
    transform: translateY(10%);
  }
}

.open-active > *:last-child {
  transform: translateY(0);
}

.panel a {
  color: white;
  text-transform: uppercase;
  font-family: "Amatic SC", cursive;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.901), 0 0 14px rgba(0, 0, 0, 0.45);
  font-size: 2em;
}

.panel p:nth-child(2) {
  font-size: 4em;
}

.open {
  font-size: 40px;
  flex: 5;
}