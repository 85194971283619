.header {
  padding: 26px 0;
  width: 100%;
  z-index: 99999;
  position: absolute;
  top: 0;
}
 .container {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

}
.headerLogo {
  max-width: 250px;
  height: auto;
}
.headerLogo img {
  width: 100%;
  height: auto;
}
.headerNav {
  max-width: 100%;
}
.headerNavMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
.headerNavMenu li a, .headerNavMenuItem{
  padding: 0 10px;
  font-weight: 600;
  color: #FFFFFF;
  cursor: pointer;
  pointer-events: auto;
font-size: 16px;
}
.headerNavMenu li a:hover {
  color: rgb(246, 116, 29);
}
.headerNavMenu li a:last-child {
  padding-right: 0;
}
@media (max-width: 768px) {
  .headerNav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 3;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  .headerNavMenu {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    gap:2rem
  }
  .headerNavMenu li a{
    padding: 20px;
    width: 100%;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .headerNavMenu li a, .headerNavMenuItem{
    color: rgb(246, 116, 29);
  }
}
.header .open {
  transform: translateX(0);
}
.header .burgerMenu {
  display: none;
  font-size: 40px;
  background: none;
  border: none;
  cursor: pointer;
  color: #464646;
  
}
@media (max-width: 768px) {
  .header .burgerMenu {
    display: block;
    color: black;

  }
}
.header .closeButton {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: #464646;
}
@media (max-width: 768px) {
  .header .closeButton {
    display: block;
  }
}