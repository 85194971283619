.hotelSection {
    margin: auto;
    width: 95%;
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;

 
 
    .sectionTitle {
        font-size: 2rem;
        margin-bottom: 20px;
        text-align: center;
        color: #333;
    }

    @media (max-width: 768px) {
        .sectionTitle {
            margin-bottom: 10px;
        }
    }

    .hotelList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-around;
    }

    @media (max-width: 768px) {
        .hotelCard {
            width: 100%!important;
        }
        .hotelSection{
            width: 100%;
        }
    }

    .hotelCard {
        padding: 20px;
        background-color: white;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
        width: 70%;
        transition: transform 0.3s ease, box-shadow 0.3s ease; // Hover animasyonu eklendi



        &:hover {
            transform: translateY(-5px); // Hover'da kart biraz yukarı hareket eder
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); // Gölge yoğunluğu artırılır
        }

        .hotelImage {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }

        @media (max-width: 768px) {
            .hotelImage {
                height: 200px;

            }
        }

        .hotelInfo {
            padding: 15px;

            .hotelName {
                font-size: 1.5rem;
                margin-bottom: 10px;
                color: #007bff;
                transition: color 0.3s ease; // Metin rengi hover'da değişir

                &:hover {
                    color: #0056b3;
                }
            }

            .hotelDescription {
                font-size: 1rem;
                color: #666;
                margin-bottom: 15px;
                line-height: 1.5; // Daha rahat okunabilirlik için satır yüksekliği eklendi
            }

            .button {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .hotelLink {
                font-size: 1rem;
                color: white;
                padding: 1rem 1.5rem;
                background-color: orange;
                text-decoration: none;
                font-weight: 600;
                border-radius: 5%;
                transition: color 0.3s ease, text-decoration 0.3s ease;

                &:hover {
                    color: #c4c3c3;
                    text-decoration: underline;
                }
            }
        }
    }
}