.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: _;
  gap: 1rem;
  padding-inline: 55px;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .card {
    display: flex;
    align-items: center;
    padding-inline: 0;
  }
}
.card:nth-child(1) {
  margin-top: 2rem;
}
.card_img {
  max-width: 100px;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .card_img {
    min-width: 120px;
    min-height: 100px;
  }
}
.card_img > img {
  max-width: 100%;
}
.card_title {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 13px;
  color: #111;
}
@media (max-width: 768px) {
  .card_title {
    letter-spacing: normal;
    text-transform: capitalize;
  }
}
.card_desc {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.42857143;
  color: #999;
  line-height: 1.5;
  margin-top: 0.8rem;
}