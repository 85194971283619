.card {
  width: 90%;
  margin: 20px 0;
  cursor: pointer;
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    height: auto;
    margin: 40px 0;
  }
}
.card_img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 768px) {
  .card_img {
    height: 200px;
  }
}
.card_img img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 768px) {
  .card_img img {
    height: 200px;
  }
}
.card_detail {
  width: 100%;
  opacity: 0;
  bottom: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  text-align: center;
  font-size: 12px;
  color: #aaa;
  transition: all 0.3s ease-in-out 0s;
  padding: 20px;
}
.card_detail > h5 {
  font-family: roboto condensed, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
}
.card_detail > p {
  font-style: italic;
  font-size: 16px;
  margin-top: 1rem;
  text-align: center;
}
.card_bottom {
  text-align: center;
  margin-top: 1rem;
}
.card_bottom_desc {
  font-size: 16px;
  color: #aaa;
}
@media (max-width: 768px) {
  .card_bottom_desc {
    font-size: 14px;
  }
}