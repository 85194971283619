.home {
  width: 100%;
  position: relative;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../images/banner.webp");
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
@media (max-width: 768px) {
  .home {
    background: none;
    color: black;
    padding-top: 150px;
  }
}

@media (max-width: 768px) {
  .homeDescription {
    background: none;
    padding: 10px;
  }
}
.homeDescription > h1 {
  margin-bottom: 17px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .homeDescription > h1 {
    margin: 15px;
    font-size: 18px;
    line-height: normal;
    text-align: center;
    color: #464646;
  }
}
.homeDescription > p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  font-size: 20px;
  line-height: 34px;
  -webkit-tap-highlight-color: transparent;
  font-weight: 300;
  color: #ffffff;
}
@media (max-width: 768px) {
  .homeDescription > p {
    font-size: 14px;
    line-height: normal;
    text-align: center;
    color: #464646;
  }
}
.homeForm {
  background: rgba(255, 255, 255, 0.679);
  position: relative;
  border-radius: 16px;
  z-index: 1;
  width: 100%;
  color: white;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  margin: auto;
}
@media (max-width: 768px) {
  .homeForm {
    width: 95%;
    margin: auto;
    background: none;
    margin-bottom: 10px;
  }
}
.homeFormBanner {
  width:100%;
}

.container {
  width: 95%;
  max-height: 100%;
  padding: 3rem;
}
@media (max-width: 768px) {
  .container {
    padding: 0;
    flex-direction: column;
  }
}
