section {
  margin: 50px 0;
}
section h1 {
  text-align: center;
  font-size: 30px;
  margin: 50px 0;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 90%;
  margin: auto;
}
@media (max-width: 768px) {
  .cards {
    flex-direction: column;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: transform 0.3s;
}

.card img {
  width: 200px;
  border-radius: 50%;
}

.card h3,
.card p {
  text-align: center;
}

.card:hover {
  transform: scale(1.1);
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.contentBox {
  display: none;
}
.singleCard {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
}
.contentBox.active {
  display: block;
  margin: auto;
  padding: 10px 20px;
  border-radius: 3%;
}

.text p {
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .content {
    width: 100%;
    text-align: center;
  }
}
