.container {
  width: 100%;
  margin: auto;
  display: grid;
  height: auto;
  place-items: center;
  
}

.accordionWrapper {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 60rem;
  padding: 2rem;
}

.accordionTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 4rem;
}

.accordion {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.accordionIcon {
  background-color: #333;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
}

.accordionQuestion {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
}

.accordionAnswer {
  padding: 2rem 0;
}

.accordionContent {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
}

@media screen and (max-width: 768px) {
  .accordionWrapper {
    padding: 2rem 0;
    width: 100%;
  }

  .container{
    width: 100%;
  }
  .accordionTitle{
    margin: 10px 0;
    font-size: 26px;
  }
  .accordion{
    padding: 0 20px;
  }
}