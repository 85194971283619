.banner {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  position: relative;
}
@media (max-width: 768px) {
  .banner {
    height: 30vh;
  }
}
.banner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
}
.banner .text {
  text-align: center;
  margin-top: 150px;
  position: absolute;
  color: white;
  font-size: 42px;
  font-weight: 700;
  width: 100%;
}
.text h2{
  color: white;
}
.cuisine {
  margin: auto;
  width: 95%;
  position: relative;
  z-index: 1;
}

.cuisine_text {
  margin: 30px 0;
}
.cuisine_text_title {
  letter-spacing: 4px;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
@media (max-width: 768px) {
  .cuisine_text_title {
    font-size: 20px;
    letter-spacing: normal;
  }
}
.cuisine_text_desc {
  width: 550px;
  text-align: center;
  font-style: italic;
  margin: auto;
}
@media (max-width: 768px) {
  .cuisine_text_desc {
    width: auto;
  }
}
.cuisine_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 768px) {
  .cuisine_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}