.casino {
  margin-top: 4rem;
  padding-inline: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6rem;
}
@media (max-width: 768px) {
  .casino {
    padding: 10px 0;
    margin-top: 1rem;
    text-align: center;
    gap: 0;
    width: 100%;
  }
}
.casino_text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.casino_text_title {
  letter-spacing: 4px;
  font-weight: 400;
  font-size: 30px;
}
@media (max-width: 768px) {
  .casino_text_title {
    font-size: 20px;
    letter-spacing: normal;
  }
}
.casino_text_desc {
  font-family: Volkhov, times new roman, sans-serif;
  width: 550px;
  text-align: center;
  font-style: italic;
}
@media (max-width: 768px) {
  .casino_text_desc {
    width: 100%;
  }
}
.casino_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: _;
  gap: 1rem;
  flex-wrap: wrap;
}
.casino_wrapper_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}
@media (min-width: 769px) {
  .casino_wrapper_cards {
    width: 48%;
  }
}
.casino_wrapper_img {
  width: 100%;
  max-width: 517px;
  margin-top: 2rem;
}
.casino_wrapper_img > img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .casino_wrapper_img {
    display: none;
  }
}