body,
html,
.root {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: hsl(0, 0%, 97%);
  color: #333;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
}
