.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  background-color: #f0f0f0;
  position: relative;
}
@media (max-width: 768px) {
  .banner {
    height: 30vh;
  }
}
.banner .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.banner .image-wrapper img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.banner .content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}
@media (max-width: 768px) {
  .banner .content {
    display: none;
  }
}
.banner .content h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.banner .content p {
  font-size: 1.2rem;
  color: white;
}

.text {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 30px 0;
}
@media (max-width: 768px) {
  .text {
    width: 95%;
  }
}
.text h2 {
  font-size: 20px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .text h2 {
    font-size: 16px;
  }
}
.text li {
  margin: 10px 0;
} 
